<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-10">
        <div class="row">
          <div class="col">
            <h3>
              Gewichte
              <small class="text-muted">
                <span v-if="!requesting">{{weights.length}}</span>
                <i class="fa fa-fw fa-pulse fa-spinner" v-if="requesting"></i>
              </small>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="weights.length">
      <div class="col-12 col-xl-10">
        <weights-table
          :weights="weights"
          :requesting="requesting"
          :role="role"
          @update="onWeightUpdate"
        ></weights-table>
      </div>
      <div class="col-12 col-xl-2">
        <div class="card">
          <div class="card-body">
            <create-weight-form
              @createWeight="onCreateWeight"
              :requesting="requesting"
              :role="role"
            ></create-weight-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateWeightForm from '@/components/weights/CreateWeightForm';
import WeightsTable from '@/components/weights/WeightsTable';
import { auth } from '@/firebase';
import { getWeights, createWeight, updateWeight } from '@/api';

export default {
  name: 'weights',
  components: {
    CreateWeightForm,
    WeightsTable
  },
  data() {
    return {
      weights: [],
      requesting: false,
      role: ''
    };
  },
  async created() {
    this.requesting = true;

    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult();

      this.role = idTokenResult.claims.role;

      const weights = await getWeights();

      this.weights = weights;
      this.requesting = false;
    } catch (err) {
      console.error(err);
      this.requesting = false;
    }
  },
  methods: {
    async onCreateWeight(weight) {
      try {
        const res = await createWeight(weight);

        this.weights.push(res);
      } catch (err) {
        console.error(err);
      }
    },
    async onWeightUpdate({ id, type, value, amount }) {
      try {
        await updateWeight(id, { type, value, amount });
      } catch (err) {
        console.error(err);

        alert('Fehler aufgetreten, bitte Admin informieren.');
      }
    }
  }
};
</script>
