<template>
  <table class="table table-sm table-bordered table-sm">
    <thead>
      <tr>
        <th>Typ</th>
        <th class="text-right">Wert</th>
        <th class="text-right">Anzahl</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="weight in weights" :key="weight._id">
        <td>{{weight.type}}</td>
        <td width="1%" class="text-right">{{weight.value}}</td>
        <td width="1%" class="text-right">
          <input
            type="number"
            class="form-control form-control-sm"
            v-model.number="weight.amount"
            @change="onAmountChange(weight._id, weight)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'weights-table',
  props: {
    weights: {
      type: Array,
      required: true
    },
    requesting: {
      type: Boolean,
      required: true
    },
    role: {
      type: String,
      default: 'guest'
    }
  },
  methods: {
    onAmountChange(id, weight) {
      const { type, value, amount } = weight;

      this.$emit('update', {
        id,
        type,
        value,
        amount
      });
    }
  }
};
</script>
